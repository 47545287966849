
import React, { useState, useMemo, useEffect,useContext } from "react";
import { Link,useNavigate } from 'react-router-dom';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import ProgressBar from "react-bootstrap/ProgressBar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import IdCardicon from "../images/user/card-icon.svg";

import UserFooter from "../components/user-footer/user-footer";
import { selectDocumentType } from "../services/user";
import UserContext from "../store/user-context";

const ChooseDocment = () => {

    const [selectedCountry, setSelectedCountry] = useState('');
    const [documentType, setDocumentType] = useState('');
    const { userState:{email}, setUserState } = useContext(UserContext)
    const navigate= useNavigate()

    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = (selectedOption) => {
        setSelectedCountry(selectedOption.label);
    }

    useEffect(()=>{
        const submit=async()=>{
            try{
              if(selectedCountry.length>0 && documentType.length>0){
                const resp=await selectDocumentType({email:email,selectedType:documentType,selectedRegion:selectedCountry})
                if(resp.success){
                  console.log(resp)
                  navigate('/upload-doc',{state:{documentType:documentType}})
                }
                else{
                  console.log(resp)
                }
              }
              
            }
            catch(e){
              console.log(e)
            }
          }

          if(selectedCountry.length>0 && documentType.length>0)
            submit()

    },[selectedCountry,documentType])

    console.log(selectedCountry)

    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body text-start">
                            <ProgressBar animated now={25} />
                            <div className = "inner-body text-start">
                                <h1 className = "text-center">
                                    Choose your document
                                </h1>
                                
                                <div className = "choose-doc">
                                    <div className = "select-country">
                                        <label>Issuing country*</label>
                                        <Select options={options} defaultValue={selectedCountry} placeholder = {'Select Country'} onChange={changeHandler}/>
                                    </div>
                                    {selectedCountry && ( 
                                        <div className = "doc-sec">
                                            <label>
                                                Choose which ID you’d like to provide
                                            </label>
                                            <Link onClick={()=>{setDocumentType('Passport')}} className = "select-doc-type">
                                                <div className = "doc-icon">
                                                    <img src = {IdCardicon} alt = "icon"/>
                                                </div>
                                                <div className = "doc-name">
                                                    <div className = "title">Passport</div>
                                                    <div className = "type">Photo page</div>
                                                </div>
                                            </Link>
                                            <Link onClick={()=>{setDocumentType('ID')}} className = "select-doc-type">
                                                <div className = "doc-icon">
                                                    <img src = {IdCardicon} alt = "icon"/>
                                                </div>
                                                <div className = "doc-name">
                                                    <div className = "title">National ID Card</div>
                                                    <div className = "type">Front and back</div>
                                                </div>
                                            </Link>
                                        </div>
                                     )} 
                                </div>

                            </div>
                        </div>
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ChooseDocment;