// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.scan-face-section .face-scanner {
    width: 229px;
    height: 271px;
    margin: 20px auto 30px auto;
    background: #D9D9D9;
    border: 8px solid #4080FF;
    border-radius: 50%;
}

.scan-face-section .btn {
    max-width: 230px;
    width: 25%;
    /* margin: auto; */
}

.secondbtn{
    background-color: white;
    color: #4080FF;
    border-color: #4080FF;
    border: 2px solid var(--btn-primary-bg);
    max-width: 400px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
}

.canvas-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

`, "",{"version":3,"sources":["webpack://./src/components/scan-face/face-scanner.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,qBAAqB;IACrB,uCAAuC;IACvC,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;EAC7B","sourcesContent":["\n\n.scan-face-section .face-scanner {\n    width: 229px;\n    height: 271px;\n    margin: 20px auto 30px auto;\n    background: #D9D9D9;\n    border: 8px solid #4080FF;\n    border-radius: 50%;\n}\n\n.scan-face-section .btn {\n    max-width: 230px;\n    width: 25%;\n    /* margin: auto; */\n}\n\n.secondbtn{\n    background-color: white;\n    color: #4080FF;\n    border-color: #4080FF;\n    border: 2px solid var(--btn-primary-bg);\n    max-width: 400px;\n    padding-top: 15px;\n    padding-bottom: 15px;\n    font-size: 20px;\n    font-weight: 500;\n}\n\n.canvas-container {\n    position: relative;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
