import React, { Fragment } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { Button, Container } from "react-bootstrap"

import UserContext from './store/user-context'

import Header from './components/navbar/Navbar'
import UserVerification from "./pages/user-verification";
import ChooseDocment from "./pages/choose-user-doc";
import UploadDocument from "./pages/upload-document";
import ScanFace from "./pages/scan-face";
import DoKyc from "./pages/DoKyc";

const Router = () => {
    const { userState: { isAuth, email }, setUserState } = useContext(UserContext)
    const [isLoaded, setIsLoaded] = useState(false)
    const navigate = useNavigate();
    const videoRef = React.createRef(null);
    const webcamRef = React.createRef(null);
    const logOutBtnHandler = (navigateTo = "/") => {
        console.log(navigateTo)
        if (navigateTo !== '/') {
            navigate('/')
            setIsLoaded(false)
        }
        localStorage.removeItem('token')
        localStorage.removeItem('email')
        localStorage.removeItem('tokenExpiry')
        setUserState({ isAuth: false, token: null, tokenExpiry: null, email: '' })
        if (webcamRef.current !== null) {
            let stream = webcamRef.current.stream;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
        }
        if (videoRef.current !== null) {
            videoRef.current.pause();
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(function (track) {
                track.stop();
            });
        }
        if (navigateTo == '/')
            navigate(navigateTo)
        else
            window.location.href = navigateTo;
    }

    useEffect(() => {
        setIsLoaded(false)
        const token = localStorage.getItem('token')
        const email = localStorage.getItem('email')
        const tokenExpiry = localStorage.getItem('tokenExpiry')
        if (!token || !email) {
            setIsLoaded(true)
            return
        }
        setUserState({ isAuth: true, token: token, tokenExpiry: tokenExpiry, email: email })
        setIsLoaded(true)
    }, [])

    console.log(isAuth)
    console.log(isLoaded)

    return (
        <Fragment>
            <Header logOutBtnHandler={logOutBtnHandler} />
            {isLoaded ?
                <Routes>
                    {
                        isAuth ?
                            <Route>
                                <Route path='/' element={<ChooseDocment />} />
                                <Route path='choose-doc' element={<ChooseDocment />} />
                                <Route path='upload-doc' element={<UploadDocument ref={webcamRef} />} />
                                <Route path='scan-face' element={<ScanFace ref={videoRef} />} />
                                <Route path='/do-kyc' element={<DoKyc logOutBtnHandler={logOutBtnHandler} />} />
                            </Route>
                            :
                            <Route path='/' element={<UserVerification />} />
                    }
                    <Route
                        path='*'
                        element={
                            <div className='heading' style={{ marginTop: '150px' }}>
                                <Container className='d-flex flex-column '>
                                    <h1>There's nothing here. You've taken a wrong turn!</h1>
                                    <Button className='w-25' style={{ fontSize: '1.5rem' }} onClick={() => navigate(-1)}>Go Back</Button>
                                </Container>
                            </div>
                        }
                    />

                </Routes>
                :
                <>
                </>
            }
        </Fragment>
    )
}

export default Router