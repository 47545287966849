
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from 'react-bootstrap';
import {Spinner} from 'react-bootstrap';

import ClockIcon from "../images/user/time-icon.svg";
import IdCardicon from "../images/user/card-icon.svg";
import Ageicon from "../images/user/18+icon.svg";

import VerificationStep from "../components/user-verification-steps/verification-step";
import UserFooter from "../components/user-footer/user-footer";
import  UserContext  from '../store/user-context';

import { useNavigate,useLocation  } from 'react-router-dom';
import { loginUser } from '../services/user';


const UserVerification = () => {

    const [message,setMessage]=useState('')
    const [submitting,setSubmitting]=useState(false)

    const { userState, setUserState } = useContext(UserContext)
    const navigate=useNavigate()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const accessKey=queryParams.get('accesskey');
    const referrer=document.referrer
    console.log(email)
    console.log(document.referrer)

    const handleLogin = async (e) => {
        try {
          const resp = await loginUser({email:email,accessKey:accessKey,referrer:referrer})
          console.log(resp)
          if(resp.token){
            localStorage.setItem('token', resp.token)
            localStorage.setItem('email', email)
            localStorage.setItem('tokenExpiry',resp.tokenExpiry)
            setUserState({ isAuth: true, token: resp.token,tokenExpiry:resp.tokenExpiry,email:email })
            navigate('/')
          }
          else{
            setMessage(resp.message)
            setSubmitting(false)
          }
        }
        catch(e){
          console.log(e)
        }
    };
    
    function isValidEmail(email) {
        // Regular expression pattern for email validation
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        
        return emailPattern.test(email);
    }


    console.log(message)

    const submit =async ()=>{
        setSubmitting(true)
        if(email){
          if(isValidEmail(email)){
            if(accessKey){
                if(referrer.length>0)
                    await handleLogin()
                else
                {
                    setMessage('MWAN KYC provides service to only registered websites, you cannot do a standalone KYC')
                    setSubmitting(false)
                }
            }
            else{
              setMessage('No Access Key found')
              setSubmitting(false)
            }
          }
          else{
            setMessage('Invalid Email')
            setSubmitting(false)
          }
        }
        else{
          setMessage('No Email found, please contact your website for help')
          setSubmitting(false)
        }
    }


    return (
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>
                        <div className = "user-body">
                            <div className = "inner-body">
                                <h1>
                                    Before we start Verification
                                </h1>
                                <p>
                                    To speed up your account opening process, please provide accurate personal 
                                    information for identity verification.
                                </p>
                                <h2>
                                    You will need the following:
                                </h2>
                                <div className = "verification-steps">
                                    
                                    <VerificationStep Stepicon={ClockIcon} StepMessage={'10 mins of your time to complete this process'}/>

                                    <div className = "step-space">
                                        <div className = "space-line"></div>
                                    </div>

                                    <VerificationStep Stepicon={IdCardicon} StepMessage={`could be an identity card or passport`}/>

                                    <div className = "step-space">
                                        <div className = "space-line"></div>
                                    </div>

                                    <VerificationStep Stepicon={Ageicon} StepMessage={'You must be over 18 years of age'}/>
                                </div>
                                <Button onClick={submit} className = "btn btn-primary" disabled={message.length>0}>
                                    {!submitting ? "Start Verification": <Spinner/>}
                                </Button>

                                {message.length>0 && <h5 style={{marginTop:'15px',color:'red'}}>{message}</h5>}
                            </div>
                        </div>
                       <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UserVerification;