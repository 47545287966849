import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
import { Camera } from 'react-bootstrap-icons';
import './capture.css';

const CaptureId = React.forwardRef(({ picture, setPicture, submit }, webcamRef) => {
  const [takePicture, setTakePicture] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timer;
    if (takePicture) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [takePicture]);

  useEffect(() => {
    if (counter === 1) {
      document.getElementById("btn-1").classList.remove("active");
      document.getElementById("btn-2").classList.add("active");
    } else if (counter === 2) {
      document.getElementById("btn-2").classList.remove("active");
      document.getElementById("btn-3").classList.add("active");
    } else if (counter === 3) {
      document.getElementById("btn-3").classList.remove("active");
      document.getElementById("btn-camera").classList.add("active");
      setTimeout(() => {
        captureScreenshot();
        setTakePicture(false);
      }, 1000);
    }
  }, [counter]);

  const handleCapture = () => {
    setTakePicture(true);
    setCounter(0);
  };

  const captureScreenshot = () => {
    const screenshot = webcamRef.current.getScreenshot();
    setPicture(screenshot);
  };

  const retake = () => {
    setPicture('')
    setTakePicture(false)
  }


  return (
    <>
      {<div className="camera-sec">
        <div className="camera-body">
          {picture.length > 0 ? (
            <img src={picture} alt="Captured Image" />
          ) : (
            <>
              <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
              <div className="overlay-div1"></div>
              <div className="camera-overlay1"></div>
              <div className="overlay-div2"></div>
              <div className="camera-overlay2"></div>
              <div className="overlay-div3"></div>
              <div className="camera-overlay3"></div>
              <div className="overlay-div4"></div>
              <div className="camera-overlay4"></div>
            </>
          )}
        </div>
      </div>}

      {picture.length > 0 ?
        <div className="final-action">
          <Row>
            <Col md={7}>
              <Button onClick={submit} className="btn btn-primary">
                Yes, Its Clear
              </Button>
            </Col>
            <Col md={5}>
              <Button onClick={retake} variant="primary-outline">
                Retake
              </Button>
            </Col>
          </Row>
        </div>
        :
        !takePicture ? (
          <Button onClick={handleCapture} className="btn btn-primary">
            Capture
          </Button>
        ) : (
          <div className="capture-timer mb-3">
            <ul className="nav justify-content-center">
              <li className="nav-item">
                <Button id="btn-1" variant="default" className="active">
                  1
                </Button>
              </li>
              <li className="nav-item">
                <Button id="btn-2" variant="default">
                  2
                </Button>
              </li>
              <li className="nav-item">
                <Button id="btn-3" variant="default">
                  3
                </Button>
              </li>
              <li className="nav-item">
                <Button id="btn-camera" variant="default">
                  <Camera />
                </Button>
              </li>
            </ul>
          </div>
        )}
    </>
  );
});

export default CaptureId;
