import { useEffect,useContext, useState } from "react";
import { doKyc } from "../services/user";
import UserContext from "../store/user-context";
import { Container,Row,Col,Button,ProgressBar } from "react-bootstrap";
import VerifyIcon from "../images/user/verify-animation.webp";

import UserFooter from "../components/user-footer/user-footer";
import CompleteVerification from "../images/user/complete.svg";
import FailedVerification from "../images/user/failed.svg"



const DoKyc=({logOutBtnHandler})=>{

    const { userState:{email}} = useContext(UserContext)
    const [result,setResult]=useState('')
    const [processingState,setProcessingState]=useState('p')
    const [redirectUrl,setRedirectUrl]=useState('')
    const [counter,setCounter]=useState(5)
    const [redirectClicked,setRedirectClicked]=useState(false)

    useEffect(()=>{
        const getResult=async()=>{
            try{
                console.log(email)
                const resp=await doKyc(email)
                console.log(resp)
                setResult(resp.msg)
                if(resp.success){
                    setProcessingState('s')
                }
                else{
                    setProcessingState('f')
                }
                if (resp.redirectUrl){
                    setRedirectUrl(resp.redirectUrl)
                }
            }
            catch(e)
            {
                console.log(e)
            }
        }
        if (email)
        {getResult()}
    },[email])

    useEffect(() => {
        if (counter <= 0) {
          if (redirectUrl.length > 0)
            logOutBtnHandler(redirectUrl);
          else
            logOutBtnHandler();
        }
        else if(counter<5){
            finishProcess()
        }
      }, [counter]);

    const finishProcess=()=>{
        setRedirectClicked(true)
        setTimeout(() => {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);
    }

    return(
        <section className = "main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs = {12}>

                        {processingState=='p'?
                            <div className = "user-body">
                                <ProgressBar animated now={100} />

                            
                            <div className = "inner-body">
                                <h1>
                                    Verifying..
                                </h1>
                                <p>
                                    Please wait while we are verifying your information
                                </p>
                                <p>
                                    <img className = "verify-icon" src = {VerifyIcon} alt = "verify"/>
                                </p>
                                
                            </div>
                        </div>:

                        processingState=='s'?
                        <div className = "user-body">
                            <ProgressBar animated now={100} />
                            <div className = "inner-body">
                                <p>
                                    <img className = "complete-verification" src = {CompleteVerification} alt = "icon"/>
                                </p>
                                <h1>
                                    Successfully Verified
                                </h1>
                                {redirectClicked && <p>
                                    You will be redirected in {counter} seconds
                                </p>}
                                <p className = "mt-3 mt-lg-5">
                                    <Button onClick={finishProcess} className = "btn btn-primary btn-gotit">
                                        Got it
                                    </Button>
                                </p>
                                
                            </div>
                        </div>:
                            <div className = "user-body">
                                <ProgressBar animated now={100} variant="danger"/>

                                <div className = "inner-body">
                                    <p>
                                        <img className = "complete-verification" src = {FailedVerification} alt = "icon"/>
                                    </p>
                                    <h1>
                                        Verification Failed
                                    </h1>
                                    <p style={{fontSize:'16px',fontWeight:'450'}}>{result}</p>
                                    {redirectClicked && <p>
                                        You will be redirected in {counter} seconds
                                    </p>}
                                    <p className = "mt-3 mt-lg-5">
                                        <Button onClick={finishProcess} className = "btn btn-primary btn-gotit">
                                            Got it
                                        </Button>
                                    </p>
                                    
                                </div>
                            </div>
                        }
                        <UserFooter/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DoKyc;