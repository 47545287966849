// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.navbar-main {
    background-color: #fff;
}

.navbar-main .btn-language {
    background: #EFF3FC;
    color: #464B53;
}

.navbar-main .btn-language img {
    margin-right: 10px;
}

.dropdown-item{
    padding-top: 10px;
    padding-bottom: 10px;
    width: 200px;
}

.navbar-main .mobile-collapse {
    position: relative;
    overflow: hidden;
  }
  
  .mobile-dropdown-menu {
    right: 0;
    left: auto !important;
    transform: translateX(-100%);
  }

  .toggle{
    background-color: #EFF3FC;
    color:#464B53;
    border: none;
    border-radius: 2%;
  }
  .toggle:hover{
    background-color: #EFF3FC;
    color:#464B53;
    border: none;
  }

  .btn-check:checked+.btn, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    background-color: #EFF3FC;
    color:#464B53;
    border: none;

  }`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":";;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,QAAQ;IACR,qBAAqB;IACrB,4BAA4B;EAC9B;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;;EAEd","sourcesContent":["\n\n.navbar-main {\n    background-color: #fff;\n}\n\n.navbar-main .btn-language {\n    background: #EFF3FC;\n    color: #464B53;\n}\n\n.navbar-main .btn-language img {\n    margin-right: 10px;\n}\n\n.dropdown-item{\n    padding-top: 10px;\n    padding-bottom: 10px;\n    width: 200px;\n}\n\n.navbar-main .mobile-collapse {\n    position: relative;\n    overflow: hidden;\n  }\n  \n  .mobile-dropdown-menu {\n    right: 0;\n    left: auto !important;\n    transform: translateX(-100%);\n  }\n\n  .toggle{\n    background-color: #EFF3FC;\n    color:#464B53;\n    border: none;\n    border-radius: 2%;\n  }\n  .toggle:hover{\n    background-color: #EFF3FC;\n    color:#464B53;\n    border: none;\n  }\n\n  .btn-check:checked+.btn, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {\n    background-color: #EFF3FC;\n    color:#464B53;\n    border: none;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
