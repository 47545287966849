import React, { useState, useEffect, useRef } from "react";
import * as faceapi from 'face-api.js';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./face-scanner.css";
import "../capture-id/capture.css";

const FaceScanner = React.forwardRef(({ submitSelfie }, videoRef) => {
    const [image, setImage] = useState("")
    const [modelsLoaded, setModelsLoaded] = useState(false);
    const [captureVideo, setCaptureVideo] = useState(false);
    const [color, setColor] = useState('#4080FF')
    const [text, setText] = useState("")
    const [ready, setReady] = useState(false)
    const videoHeight = 360;
    const videoWidth = 480;

    const canvasRef = useRef();

    useEffect(() => {
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models';

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            ]).then(setModelsLoaded(true));
        }
        loadModels();

        startVideo();

    }, []);

    useEffect(() => {
        if (image.length > 0) {
            setColor('#4080FF')
        }
    }, [image, color])


    const startVideo = () => {
        setCaptureVideo(true);
        navigator.mediaDevices
            .getUserMedia({ video: { width: 300 } })
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
            })
            .catch(err => {
                console.error("error:", err);
            });
    }

    const handleVideoOnPlay = () => {
        setInterval(async () => {
            if (canvasRef && canvasRef.current) {
                const displaySize = {
                    width: videoWidth,
                    height: videoHeight
                }

                faceapi.matchDimensions(canvasRef.current, displaySize);

                const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();


                if (ready) {

                    if (detections.length > 0) {
                        setText("Face Detected, Please Stay Stable")
                        if (image.length === 0)
                            setColor('#39FF14')
                        setTimeout(() => capturePicture(), 3000);
                    }
                    else {
                        setText("No Face Detected")
                        if (image.length === 0)
                            setColor('red')
                    }
                }


            }
        }, 100)
    }

    const clickReady = () => {
        setReady(true)
        setText('Loading face detecter')
        startVideo()
    }

    console.log(ready)

    const capturePicture = async () => {
        if (canvasRef && canvasRef.current) {
            console.log('picture taken');
            const context = canvasRef.current.getContext('2d');

            context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
            const dataURL = canvasRef.current.toDataURL();
            setImage(dataURL)
            setReady(false)

            if (dataURL !== null) {
                if (videoRef.current && videoRef.current.srcObject) {
                    closeWebcam();
                }
            }
        }
    };

    const closeWebcam = () => {

        videoRef.current.pause();
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(function (track) {
            track.stop();
        });
        setCaptureVideo(false);
    };

    const RetakePicture = () => {
        setImage("")
        setText("")
        setReady(false)
        startVideo()
        setColor('#4080FF')
    };

    const maskShapeStyles = {
        width: "229px",
        height: "271px",
        margin: "20px auto 30px auto",
        borderRadius: "50%",
        position: "relative",
        overflow: "hidden",
        borderColor: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    console.log(color)

    const webcamContainerStyles = {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };



    return (
        <div className="scan-face-section">

            {
                modelsLoaded ?
                    <div className="face-scanner" style={maskShapeStyles}>
                        {captureVideo ?
                            <div style={webcamContainerStyles}>
                                <video
                                    height={videoHeight}
                                    width={videoWidth}
                                    onPlay={handleVideoOnPlay}
                                    className="webcam-video"
                                    ref={videoRef}
                                />
                                <div className="canvas-container">
                                    <canvas ref={canvasRef} style={{ position: 'absolute' }} />
                                </div>
                            </div> :
                            <div style={webcamContainerStyles}>
                                <img style={{ width: videoWidth, height: videoHeight }} alt="true" className="screenshot" src={image} />
                            </div>}
                    </div>
                    :
                    <div>loading...</div>


            }
            {text !== "" && ready && captureVideo && <h5 style={{ textAlign: 'center', color: color, marginTop: "-20px", marginBottom: '15px' }}>{text}</h5>}
            {image.length === 0 && captureVideo ?
                <Button onClick={clickReady} disabled={ready} variant="primary">
                    I'm Ready
                </Button> :
                <div>
                    <Button onClick={() => { submitSelfie(image) }} variant="primary" style={{ marginRight: '20px', marginBottom: '5px' }}>
                        Yes, It is clear
                    </Button>
                    <Button onClick={RetakePicture} variant="primary-outline" className="secondbtn" style={{ marginBottom: '5px' }}>
                        Retake
                    </Button>
                </div>
            }
        </div>
    );
});

export default FaceScanner;
