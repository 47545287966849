import { useContext, useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Container, Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { Stopwatch } from 'react-bootstrap-icons';
import logout_img from '../../images/header/logout.svg';

import Kyc_logo from "../../images/logo/KYCLogo.svg";
import Language_iocn from "../../images/header/language-icon.svg";
import UserContext from "../../store/user-context";

import "./navbar.css";

const Navbarmain = ({ logOutBtnHandler }) => {
  const { userState: { isAuth, tokenExpiry } } = useContext(UserContext);

  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (isAuth) {
      const calculateTimeRemaining = () => {
        const currentTime = new Date();
        const expiryTime = new Date(tokenExpiry);
        const timeDifference = expiryTime - currentTime;

        if (timeDifference > 0) {
          const minutesRemaining = Math.floor(timeDifference / 60000);
          const secondsRemaining = Math.floor((timeDifference % 60000) / 1000);
          setTimeRemaining(`${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`);
        } else {
          // Token has expired
          setTimeRemaining('00:00');
          logOutBtnHandler();
        }
      };

      calculateTimeRemaining();
      const timer = setInterval(calculateTimeRemaining, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isAuth, tokenExpiry, logOutBtnHandler]);

  return (
    <Navbar fixed="top" className="navbar-main">
      <Container fluid>
        <Navbar.Brand href="/" className="border-0">
          <img src={Kyc_logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            {isAuth && timeRemaining !== null && (
              <Button disabled style={{ marginRight: '5px' }} variant="language" className="d-none d-md-block">
                <Stopwatch style={{ marginRight: '3px', marginBottom: '2px' }} /> Session time: {timeRemaining}
              </Button>
            )}

            {isAuth && (
              <Button onClick={() => { logOutBtnHandler() }} variant="language" className="d-none d-md-block">
                <img src={logout_img} style={{ marginRight: '0' }} /> Logout
              </Button>
            )}

            {isAuth && timeRemaining !== null && (
              <Dropdown className="d-md-none">
                <Dropdown.Toggle className="toggle" id="dropdown-basic">
                  <span>
                    <Stopwatch style={{ marginRight: '5px', marginBottom: '2px' }} />
                    Session Time: {timeRemaining}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => logOutBtnHandler()}>
                    <img src={logout_img} style={{ marginRight: '0' }} /> Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbarmain;
