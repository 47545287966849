
import React from "react";

import "./verification-step.css";

const VerificationStep = ({Stepicon, StepMessage}) => {
    return (
        <div className = "step-sec">
            <div className = "step-icon">
                <img src = {Stepicon} alt = "icon"/>
            </div>
            <div className = "step-info">
                {StepMessage}
            </div>
        </div>
    )
}

export default VerificationStep;