// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.user-page .footer {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #32383D;
    margin-bottom: 20px;
}

.user-page .footer span {
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/user-footer/userfooter.css"],"names":[],"mappings":";;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["\n\n.user-page .footer {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 16px;\n    letter-spacing: 0.01em;\n    color: #32383D;\n    margin-bottom: 20px;\n}\n\n.user-page .footer span {\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
