import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar";

import UserFooter from "../components/user-footer/user-footer";
import FaceScanner from "../components/scan-face/face-scanner";
import { uploadSelfie } from "../services/user";
import UserContext from "../store/user-context";

const ScanFace = React.forwardRef((props, ref) => {

    const { userState: { email }, setUserState } = useContext(UserContext)
    const navigate = useNavigate()

    const submitSelfie = async (image) => {
        try {
            if (image) {
                console.log(image)
                const formData = new FormData();
                formData.append('image', image);
                formData.append('email', email);

                const resp = await uploadSelfie(formData);
                console.log(resp);
                if (resp.success) {
                    navigate('/do-kyc')
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <section className="main inner-page user-page">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <div className="user-body">
                            <ProgressBar animated now={75} />
                            <div className="inner-body">
                                <h1>
                                    Scan Your Face
                                </h1>
                                <p>
                                    Please frame your face in the Oval
                                </p>

                                <FaceScanner ref={ref} submitSelfie={submitSelfie} />

                            </div>
                        </div>
                        <UserFooter />
                    </Col>
                </Row>
            </Container>
        </section>
    )
})

export default ScanFace;