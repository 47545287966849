import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate,Link } from 'react-router-dom';

import ProgressBar from "react-bootstrap/ProgressBar";

import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import passport1 from "../images/user/sample1.png";
import passport2 from "../images/user/sample2.png";
import passport3 from "../images/user/sample3.png";

import CaptureId from "../components/capture-id/capture-id";
import PassportTest from "../components/capture-id/capture-test";

import UserFooter from "../components/user-footer/user-footer";
import UserContext from "../store/user-context";

import { uploadDocument } from "../services/user";

const UploadDocument = React.forwardRef((props, ref) => {
  const navigate = useNavigate()
  const location = useLocation();
  const { documentType } = location.state;
  const { userState: { email }, setUserState } = useContext(UserContext)

  console.log(documentType)

  const [picture, setPicture] = useState('');
  const [uploadStyle, setUploadStyle] = useState(1);

  const submit = async () => {
    try {
      if (picture) {
        const formData = new FormData();
        formData.append('image', picture);
        formData.append('email', email);
        formData.append('docNum', documentType === "ID2" ? 2 : 1);

        const resp = await uploadDocument(formData);
        console.log(resp);
        if (resp.success) {
          setPicture('');
          setUploadStyle(1)
          if (documentType === "ID")
            navigate('/upload-doc', { state: { documentType: "ID2" } })
          else
            navigate('/scan-face')
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickUpload = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };

  const handleClickCapture=()=>{
    setPicture('')
    setUploadStyle(1)
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
    setPicture(event.target.result);
    };

    if (file) {
    reader.readAsDataURL(file);
    setUploadStyle(2)
    }
  };

  

  return (
    <section className="main inner-page user-page">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="user-body text-start upload-id-passport">
              <ProgressBar animated now={documentType==="ID"?40:50} />
              <div className="inner-body text-start capture-photo">

                <div className="choose-doc">

                  <Row>
                    <Col lg={6}>
                      <h1>
                        {documentType ? documentType === "Passport" ? "Capture Passport" : "Capture Identity Card" : "Capture"}
                      </h1>
                      {uploadStyle===1?<p>
                        Position it in the frame or <span id="upload" onClick={handleClickUpload}>Upload a photo</span> a photo of the {documentType ? documentType === "Passport" ? "passport" : "ID card" : "document"}.
                      </p>:
                        <p>
                            You can <span id="upload" onClick={handleClickCapture}>Capture</span> a photo of the {documentType ? documentType === "Passport" ? "passport" : "ID card" : "document"}.
                        </p>
                      }
                      <label>
                        Please ensure the photo you take:
                      </label>
                      <div className="passport-show-img">
                        <PassportTest testImg={passport1} MessageText={'Shows all details'} />
                        <PassportTest testImg={passport2} MessageText={'Isn’t covered by anything'} />
                        <PassportTest testImg={passport3} MessageText={'Is clear and focused'} />
                      </div>
                    </Col>
                    <Col lg={6}>
                      {uploadStyle===1? <div className="Img-capture-section">
                        <p>
                          Place the <b>{documentType ? documentType === "Passport" ? "Passport (Photo page)" : documentType === "ID" ? "ID Card(Front Side)" : "ID Card(Back Side)" : "Document"}</b> within the capture window.
                        </p>
                        <CaptureId ref={ref} picture={picture} setPicture={setPicture} submit={submit} />
                      </div>:
                      <div className = "Img-capture-section">
                      <div className = "upload-photo camera-sec">
                          <img src = {picture} alt = "upload-file"/>
                      </div>
                      
                      <div className = "final-action">
                          <Row>
                              <Col md = {5}>
                                  <Button onClick={handleClickUpload} variant = "primary-outline change-photo">
                                      Change
                                  </Button>
                              </Col>
                              <Col md = {7}>
                                  <Button onClick={submit} className = "btn btn-primary">
                                      Save & Continue
                                  </Button>
                              </Col>
                          </Row>
                        </div>
                    </div>
                      }
                    </Col>
                  </Row>

                </div>

              </div>
            </div>
            <UserFooter />
          </Col>
        </Row>
      </Container>

      {/* Hidden file input */}
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
    </section>
  )
});

export default UploadDocument;
